/** @jsxImportSource @emotion/react */
import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Box,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { BASE_API_URL } from '@src/app-constants'
import { Icon } from '@src/components/Atoms/Icon'
import { IconTypography, MoreInformationModal, useSnackbars } from '@uintel/ui-component-library'
import { AssetDialogData } from '../AddLayersDialog.types'
import {
  assetInternalAccordionIcon,
  assetInternalAccordionTitle,
  assetLayersFormAccordions,
  assetLayersFormContainer,
  assetLayersFormContent,
  assetLayersFormInternalAccordion,
  conditionalAssetItemStyling,
} from './AssetLayersForm.styles'
import { Clear } from '@mui/icons-material'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'
import { firstLetterToUpperCase } from '@src/utils/strings.utils'

export type AssetLayersFormProps = {
  assetDialogData: AssetDialogData
  onSelectAssets: (selectedAssets: MapLayer[]) => void
  onRemoveLayer: (layer: MapLayer, type: MapLayerType) => void
  selectedAssets: MapLayer[]
  selectedHazards: MapLayer[]
}

const flattenAssetDialogData = (assetDialogData: AssetDialogData): MapLayer[] => {
  const toReturn: MapLayer[] = []
  const handledIds: { [key: string]: boolean } = {}

  Object.keys(assetDialogData).map((assetMenuKey) => {
    if (!(assetMenuKey in assetDialogData)) return
    if (!('groups' in assetDialogData[assetMenuKey])) return
    if (!assetDialogData[assetMenuKey].groups.length) return

    assetDialogData[assetMenuKey].groups.map((group) => {
      group.assets.map((assetDetail) => {
        if (handledIds[assetDetail.tilesets[0].id]) return

        toReturn.push(assetDetail)
        handledIds[assetDetail.tilesets[0].id] = true
      })
    })
  })

  toReturn.sort()
  return toReturn
}

export const AssetLayersForm = ({
  assetDialogData,
  selectedAssets,
  selectedHazards,
  onSelectAssets,
  onRemoveLayer,
}: AssetLayersFormProps) => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const flatAssetList = flattenAssetDialogData(assetDialogData)

  const hasError = useMemo(() => {
    const errors = Object.keys(assetDialogData).map((assetMenuKey) => {
      if (!(assetMenuKey in assetDialogData)) {
        // eslint-disable-next-line no-console
        console.log(
          `No assetMenuKey "${assetMenuKey}" in assetDialogData: ${JSON.stringify(
            assetDialogData,
          )}`,
        )
        return true
      }
      if (!('groups' in assetDialogData[assetMenuKey])) {
        // eslint-disable-next-line no-console
        console.log(
          `No "groups" in assetDialogData[assetMenuKey]: ${JSON.stringify(
            assetDialogData[assetMenuKey],
          )}`,
        )
        return true
      }
      if (!assetDialogData[assetMenuKey].groups.length) {
        // eslint-disable-next-line no-console
        console.log(
          `Groups in assetDialogData[assetMenuKey] is empty: ${JSON.stringify(
            assetDialogData[assetMenuKey],
          )}`,
        )
        return true
      }

      assetDialogData[assetMenuKey].groups.map((group) => {
        group.assets.map((assetDetail) => {
          if (!assetDetail.tilesets[0].id) {
            // eslint-disable-next-line no-console
            console.log(`No tilesets[0].id in assetDetail: ${JSON.stringify(assetDetail)}`)
            return true
          }
          return false
        })
      })

      return false
    })

    return errors.some((error) => error)
  }, [assetDialogData])

  const [informationDetailsModal, setInformationDetailsModal] = useState({
    isOpen: false,
    helpFileName: '',
  })

  const [searchTerm, setSearchTerm] = useState('')

  const cannotAddCascadingLayer = selectedHazards && selectedHazards.length > 1

  const isAssetSelected = (asset: MapLayer) => {
    const existingAssets = selectedAssets

    const isAlreadySelected = existingAssets.some(
      (selectedAsset) => selectedAsset.tilesets[0].id === asset.tilesets[0].id,
    )
    return isAlreadySelected
  }

  const selectAsset = (selectedAsset: MapLayer) => {
    const foundAssets = selectedAssets.find((asset) => {
      return asset.type === selectedAsset.type
    })
    if (foundAssets) {
      onRemoveLayer(selectedAsset, 'asset')
      return
    }

    if (selectedAsset.is_cascading && selectedHazards && selectedHazards.length > 1) {
      displaySnackbar({
        message:
          'While multiple hazard layers are active, you cannot add a cascading layer. Please remove additional hazard layers before adding a cascading layer.',
        type: 'error',
      })
      return
    }

    const formattedSelectedAsset = {
      ...selectedAsset,
      visible: true,
      interactivityDisabled: false,
      layerType: 'asset' as MapLayerType,
    }
    onSelectAssets([formattedSelectedAsset])
  }

  // filters flatAssetList and searchTerm to only show the assets that match the search term
  const searchedAssets = useMemo(() => {
    return flatAssetList.filter((asset) => {
      return asset.display_name.toLowerCase().includes(searchTerm.toLowerCase())
    })
  }, [flatAssetList, searchTerm])

  if (hasError) {
    return (
      <Typography variant="subtitle2" align="center" marginTop={1}>
        No element available
      </Typography>
    )
  }

  return (
    <>
      <Box css={assetLayersFormContainer({ theme })}>
        <TextField
          fullWidth
          css={{ margin: `0 ${theme.spacing(1)}` }}
          label="Search"
          placeholder="Search"
          value={searchTerm}
          autoComplete="off"
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => {
                    setSearchTerm('')
                  }}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box css={assetLayersFormContent()}>
          {searchTerm.length === 0 ? (
            <Box css={assetLayersFormAccordions({ theme })}>
              {Object.keys(assetDialogData).map((assetMenuKey) => (
                <UiclAccordion
                  key={assetMenuKey}
                  header={
                    <IconTypography icon={assetDialogData[assetMenuKey].icon}>
                      {assetDialogData[assetMenuKey].title}
                    </IconTypography>
                  }
                  body={
                    <Box css={assetLayersFormInternalAccordion({ theme })}>
                      {assetDialogData[assetMenuKey].groups.map((group) =>
                        // items that are ungrouped are displayed without an accordion
                        group.groupName === 'Ungrouped' ? (
                          <List key={group.groupName} disablePadding>
                            {group.assets.map((asset) => {
                              return (
                                <ListItemButton
                                  selected={isAssetSelected(asset)}
                                  key={`${group.groupName}-${asset.type}-${asset.display_name}`}
                                  onClick={() => selectAsset(asset)}
                                  sx={{ paddingLeft: '8.6px' }}
                                  css={conditionalAssetItemStyling({
                                    theme,
                                    disallowed: !!(cannotAddCascadingLayer && asset.is_cascading),
                                  })}
                                >
                                  <ListItemIcon css={assetInternalAccordionIcon}>
                                    <IconTypography
                                      icon={firstLetterToUpperCase(asset.icon) as Icon}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={asset.display_name} />
                                  <ListItemIcon css={assetInternalAccordionIcon}>
                                    {asset.helpFileName && (
                                      <Tooltip title={asset.display_name}>
                                        <IconButton
                                          size="small"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setInformationDetailsModal({
                                              isOpen: true,
                                              helpFileName: asset.helpFileName,
                                            })
                                          }}
                                        >
                                          <Icon
                                            iconName="Info"
                                            colour={theme.palette.grey[500]}
                                            size="small"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </ListItemIcon>
                                </ListItemButton>
                              )
                            })}
                          </List>
                        ) : (
                          <UiclAccordion
                            key={group.groupName}
                            header={
                              <IconTypography css={assetInternalAccordionTitle} icon={group.icon}>
                                {group.groupName}
                              </IconTypography>
                            }
                            body={
                              <List disablePadding>
                                {group.assets.map((asset) => {
                                  return (
                                    <ListItemButton
                                      selected={isAssetSelected(asset)}
                                      key={`${group.groupName}-${asset.type}-${asset.display_name}`}
                                      onClick={() => selectAsset(asset)}
                                      css={conditionalAssetItemStyling({
                                        theme,
                                        disallowed: !!(
                                          cannotAddCascadingLayer && asset.is_cascading
                                        ),
                                      })}
                                    >
                                      <ListItemIcon css={assetInternalAccordionIcon}>
                                        <IconTypography
                                          icon={firstLetterToUpperCase(asset.icon) as Icon}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        sx={{ paddingLeft: theme.spacing(1) }}
                                        primary={asset.display_name}
                                      />
                                      <ListItemIcon css={assetInternalAccordionIcon}>
                                        {asset.helpFileName && (
                                          <Tooltip title={asset.display_name}>
                                            <IconButton
                                              size="small"
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                setInformationDetailsModal({
                                                  isOpen: true,
                                                  helpFileName: asset.helpFileName,
                                                })
                                              }}
                                            >
                                              <Icon
                                                iconName="Info"
                                                colour={theme.palette.grey[500]}
                                                size="small"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </ListItemIcon>
                                    </ListItemButton>
                                  )
                                })}
                              </List>
                            }
                          ></UiclAccordion>
                        ),
                      )}
                    </Box>
                  }
                ></UiclAccordion>
              ))}
            </Box>
          ) : (
            // iterate over searchedAssets without an accordion
            <List disablePadding>
              {searchedAssets.map((asset) => {
                return (
                  <ListItemButton
                    selected={isAssetSelected(asset)}
                    key={`searched-${asset.type}-${asset.display_name}`}
                    onClick={() => selectAsset(asset)}
                    css={conditionalAssetItemStyling({
                      theme,
                      disallowed: !!(cannotAddCascadingLayer && asset.is_cascading),
                    })}
                  >
                    <ListItemIcon css={assetInternalAccordionIcon}>
                      <IconTypography icon={firstLetterToUpperCase(asset.icon) as Icon} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ paddingLeft: theme.spacing(1) }}
                      primary={asset.display_name}
                    />
                    <ListItemIcon css={assetInternalAccordionIcon}>
                      {asset.helpFileName && (
                        <Tooltip title={asset.display_name}>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation()
                              setInformationDetailsModal({
                                isOpen: true,
                                helpFileName: asset.helpFileName,
                              })
                            }}
                          >
                            <Icon iconName="Info" colour={theme.palette.grey[500]} size="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                )
              })}
            </List>
          )}
        </Box>
      </Box>
      {informationDetailsModal.isOpen &&
        createPortal(
          <MoreInformationModal
            baseURL={BASE_API_URL}
            helpFileName={informationDetailsModal.helpFileName}
            isOpen={informationDetailsModal.isOpen}
            handleClose={() =>
              setInformationDetailsModal({
                isOpen: false,
                helpFileName: '',
              })
            }
          />,
          document.body,
        )}
    </>
  )
}
