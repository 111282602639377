/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { multiRiskAccordion } from './MultiRiskAccordion.styles'
import Box from '@mui/material/Box'
import { MultiRiskData, RiskFetcherIsLoading } from '@contexts/RiskFetcherContext/RiskFetcher.types'
import Typography from '@mui/material/Typography'
import { Barchart, ChartDataItem } from '@src/components/Molecules/Charts/Barchart'
import {
  chartContainer,
  chartHeader,
  chartSection,
  chartSizeProtector,
  riskSummary,
} from '../RiskContent/RiskContent.styles'
import { css } from '@emotion/react'
import { Gauge } from '@src/components/Molecules/Charts/Gauge'
import { MapLayer } from '@redux/map/mapSlice'
import { useCallback } from 'react'
import { getConsequencePalette, round } from '@src/components/Molecules/Charts/utils/utils'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { Accordion } from '@src/components/Atoms/Accordion'
import { ShowChart } from '@mui/icons-material'
import { FancySuspense } from '@src/components/Atoms/FancySuspense'
import { capitalizeFirstLetter } from '@src/utils/strings.utils'

export interface MultiRiskAccordionProps {
  riskMultiHazard: MultiRiskData | null
  assetLayer: MapLayer
  useCascading: boolean
  isLoading: RiskFetcherIsLoading
}

const vulnerabilityConverter = (
  vulnerability: string,
  palette: {
    [key: string]: string
  },
  isMinorMajor: boolean,
):
  | {
      label: string
      color: string
      order?: number
    }
  | undefined => {
  const trimmedVulnerability = vulnerability.replace('_vulnerability', '')
  return {
    potential: {
      label: 'Potential',
      color: palette['potential'],
      order: 2,
    },
    exposed: {
      label: 'Exposed',
      color: palette['exposed'],
      order: 1,
    },
    low: {
      label: isMinorMajor ? 'Minor' : 'Low',
      color: palette['low'],
      order: 3,
    },
    medium: {
      label: 'Moderate',
      color: palette['moderate'],
      order: 4,
    },
    high: {
      label: isMinorMajor ? 'Major' : 'High',
      color: palette['high'],
      order: 5,
    },
    unspecified: {
      label: 'Unspecified',
      color: palette['exposed'],
      order: 0,
    },
    insignificant: {
      label: 'Insignificant',
      color: palette['insignificant'],
      order: 0,
    },
  }[trimmedVulnerability]
}

export const MultiRiskAccordion = ({
  riskMultiHazard,
  assetLayer,
  useCascading,
  isLoading,
}: MultiRiskAccordionProps) => {
  const theme = useTheme()
  const xAxisTitle = `${
    assetLayer.metric_type === 'count'
      ? 'Number'
      : capitalizeFirstLetter(assetLayer.unit?.trimStart() ?? '')
  } of ${useCascading ? 'parcels' : assetLayer.display_name}`
  const vulnerabilityPalette = useSelector(
    (state: RootState) => state.map.legendsData['vulnerability'],
  )
  const amILoading = isLoading.multiHazards.includes(assetLayer.type)
  const isMinorMajor = useSelector((state: RootState) =>
    state.map.legendsData.vulnerability.sections.some(
      (section) =>
        section.items &&
        section.items.filter((item) => item.label === 'Minor' || item.label === 'Major').length > 0,
    ),
  )
  const palette = getConsequencePalette(vulnerabilityPalette) || {}
  let vulnerabilityData: ChartDataItem[] = riskMultiHazard
    ? [
        ...Object.entries(riskMultiHazard).flatMap(([key, value]) => {
          const vulnDetails = vulnerabilityConverter(key, palette, isMinorMajor)
          if (vulnDetails)
            return [
              {
                ...vulnDetails,
                value: value,
              },
            ]
          return []
        }),
      ]
    : []
  vulnerabilityData = vulnerabilityData.filter((item) => item.value)

  const consequencesPossible = useCallback(
    (
      consequenceLabel: string,
      value: number,
      suffix: string | undefined,
      prefix = '',
      type?: string,
    ) => {
      const exposure = {
        Minor: 'Minor consequences',
        Low: 'Low consequences',
        Moderate: 'Moderate consequences',
        Major: 'Major consequences',
        High: 'High consequences',
        Potential: 'Potential consequences',
        Unspecified: 'Unspecified consequences',
        Insignificant: 'Insignificant consequences',
      }[consequenceLabel]
      let roundedValue = round(value, value > 1000 ? 0 : 2).toLocaleString()
      if (roundedValue === '0' && value !== 0) roundedValue = '<0.01'
      if (useCascading)
        return `${exposure} possible for  upstream connections of ${roundedValue} parcels`
      if (type === 'Replacement Costs')
        return `${prefix}${roundedValue} worth of damage from possible ${exposure?.toLocaleLowerCase()}`
      if (consequenceLabel === 'Exposed') return `${prefix}${roundedValue} ${suffix} are exposed`
      return `${exposure} possible for ${prefix}${roundedValue} ${suffix}`
    },
    [useCascading],
  )
  const damageStateTooltipFormatter = useCallback(
    (data: ChartDataItem) => {
      return consequencesPossible(data.label, data.value, assetLayer.unit, undefined, undefined)
    },
    [assetLayer.unit, consequencesPossible],
  )

  // Check for empty data
  if (riskMultiHazard && !amILoading && !Object.keys(riskMultiHazard).includes('total_metric'))
    return null
  const confirmedRiskMultiHazard = riskMultiHazard as MultiRiskData | undefined

  return (
    <Accordion
      title={'Risk from selected sources'}
      defaultExpanded={true}
      icon={<ShowChart />}
      variant="outline"
      level="h3"
      body={
        <>
          <FancySuspense isLoading={amILoading || !confirmedRiskMultiHazard} variant="charts">
            {confirmedRiskMultiHazard && (
              <>
                <Box css={riskSummary({ theme })}>
                  {confirmedRiskMultiHazard.total_metric_exposed == 0 ? (
                    <Typography>
                      {assetLayer.display_name} aren&apos;t exposed under this collection of
                      scenarios.
                    </Typography>
                  ) : (
                    <Typography>
                      {assetLayer.metric_type === 'count' ? 'Approximately ' : 'At least '}
                      <b>
                        {round(confirmedRiskMultiHazard.total_metric_exposed, 1).toLocaleString()}
                      </b>{' '}
                      of{' '}
                      <b>
                        {round(confirmedRiskMultiHazard.total_metric, 1).toLocaleString()}
                        {assetLayer.unit}
                      </b>
                      {assetLayer.metric_type === 'count'
                        ? ''
                        : ' of ' + assetLayer.display_name.toLowerCase()}
                      &nbsp;are threatened under this collection of scenarios.
                    </Typography>
                  )}
                </Box>
                <Box css={multiRiskAccordion()}>
                  {vulnerabilityData.length > 0 && (
                    <Box css={chartSection({ theme })} key="damage-state">
                      <Box css={chartHeader({ theme })}>
                        <Typography>
                          Damage State{useCascading ? ' of Upstream Connections' : ''}
                        </Typography>
                      </Box>
                      <Box
                        css={css`
                          ${chartContainer()}
                          min-height: ${Math.max((vulnerabilityData.length + 1) * 40, 96)}px;
                        `}
                        key="damage-state-chart"
                      >
                        <Box css={chartSizeProtector()}>
                          <Barchart
                            data={vulnerabilityData}
                            xTitle={xAxisTitle}
                            tooltipFormatter={damageStateTooltipFormatter}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {confirmedRiskMultiHazard.total_metric_exposed > 0 && (
                    <Box
                      css={css`
                        ${chartSection({ theme })}
                        max-width: 200px;
                      `}
                      key="exposure"
                    >
                      <Box css={chartHeader({ theme })}>
                        <Typography paddingRight="4px">
                          Percent {useCascading ? 'Impacted' : 'Exposed'}
                        </Typography>
                      </Box>
                      <Box
                        css={css`
                          ${chartContainer()}
                          justify-content: center;
                          padding: 16px 0 0 0;
                          min-width: 80px;
                          align-self: flex-start;
                          box-sizing: border-box;

                          > div {
                            align-self: center;
                          }
                        `}
                        key="exposure-chart"
                      >
                        <Gauge
                          value={confirmedRiskMultiHazard.total_metric_exposed}
                          total={confirmedRiskMultiHazard.total_metric}
                          unit={useCascading ? 'parcels' : assetLayer.unit}
                          equalOrGreaterThan={assetLayer.metric_type !== 'count'}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </FancySuspense>
        </>
      }
    />
  )
}
