import { MapLayer } from '@redux/map/mapSlice'
import * as turf from '@turf/turf'
import { Expression, Map } from 'mapbox-gl'

import { MapContext } from '@contexts/MapContext'
import { VectorTileset } from '../LayersControl'
import {
  addIconLayer,
  addLineLayer,
  addPointLayer,
  addRasterLayer,
  addVectorLayer,
} from './LayerTypes'
import {
  InformationLayerPalette,
  InformationLayerPalettes,
  VisibleAssets,
  byAssetIdIn,
  generateVulnerabilityPalette,
  getHazardColourOfAsset,
  maxVulnerabilityExpression,
} from './RiskMapView.utilities'
import { MapLayerStyle, MapLayerStyles } from '@contexts/MapContext'
import { LegendsData } from './AddLayersDialog'
import { handleClearRemovedLayers } from '@contexts/MapContext/utils/layers'
import { AssetHazardInstances, VulnerabilityData } from './RiskMapView'

/* Force existing type to GeoJSONData */
export interface GeoJSONSourceProps extends mapboxgl.GeoJSONSource {
  _options: {
    data: {
      geometry: {
        coordinates: turf.helpers.Position[][]
      }
    }
  }
}

export function renderLayersInOrder(
  map: Map,
  layers: MapLayer[],
  layerStyles: MapLayerStyles,
  assetIdsToDisplay: VisibleAssets,
  vulnerabilityData: VulnerabilityData,
  legendsData: LegendsData,
  informationLayerPalettes: InformationLayerPalettes,
  addRegionMasksLayers: MapContext['addRegionMasksLayers'],
  regionMasks: MapContext['regionMasks'],
  hasFilters: boolean,
) {
  // clearMap({ map })
  handleClearRemovedLayers({ map, layers })
  const hazardLayers = layers.filter((layer) => layer.layerType === 'hazard')

  const firstDrawAreaLayer = map
    .getStyle()
    .layers.filter((layer) => layer.id.includes('gl-draw'))[0]

  let lastId: string

  if (firstDrawAreaLayer) {
    lastId = firstDrawAreaLayer.id
  } else {
    lastId = ''
  }

  const uiTilesets = map.getStyle().layers.filter((layer) => layer.id.includes('urbanintelligence'))

  for (const tileset of uiTilesets) {
    if (
      tileset.id.includes('_vector') &&
      !layers.some((layer) => layer.tilesets.includes(tileset))
    ) {
      map.removeLayer(tileset.id)
    }
  }

  layers.forEach((layer) => {
    const totalTilesets = layer.infoLookupTileset
      ? [...layer.tilesets, ...layer.infoLookupTileset]
      : layer.tilesets

    return totalTilesets.forEach((tileset) => {
      const foundTileSet = uiTilesets.find((l) => l.id === tileset.id)
      const shouldUpdate = !!map.getSource(tileset.id) && !!foundTileSet
      const lookupTileSet =
        layer?.infoLookupTileset?.length &&
        layer.infoLookupTileset.find((lookupTileset) => lookupTileset.id === tileset.id)
      if (lookupTileSet) {
        renderHazardInfoLayer({
          map,
          layer,
          layerStyles,
          vectorTileset: lookupTileSet as VectorTileset,
          isVisible: layer.visible,
          beforeId: lastId,
          legendsData,
          shouldUpdate,
        })
        if (lastId) map.moveLayer(tileset.id, lastId)
        lastId = tileset.id
        return
      }
      switch (layer.layerType) {
        case 'asset':
          renderAssetLayer(
            map,
            layer,
            layerStyles,
            assetIdsToDisplay[layer.type],
            hasFilters,
            tileset as VectorTileset,
            layer.visible,
            lastId,
            vulnerabilityData[layer.type],
            legendsData,
            hazardLayers,
            shouldUpdate,
          )
          if (lastId) map.moveLayer(tileset.id, lastId)
          lastId = tileset.id
          break

        case 'information':
          renderInformationLayer(
            map,
            layerStyles,
            tileset as VectorTileset,
            layer.visible,
            lastId,
            informationLayerPalettes[layer.legend],
            shouldUpdate,
          )
          if (lastId) map.moveLayer(tileset.id, lastId)
          lastId = tileset.id
          break

        case 'hazard':
          addRasterLayer({
            map,
            id: tileset.id,
            isVisible: layer.visible,
            beforeId: lastId,
            shouldUpdate,
          })
          if (lastId) map.moveLayer(tileset.id, lastId)
          lastId = tileset.id
          break
      }
    })
  })

  if (regionMasks) addRegionMasksLayers({ regionMasks: regionMasks, map })

  const selectionLayer = map.getLayer('selectionToolLineLayer')
  const selectionAreaLayer = map.getLayer('selectionToolAreaLayer')
  if (selectionLayer) {
    // Put the selection tool layer on top of all layers
    map.moveLayer(selectionAreaLayer.id)
    map.moveLayer(selectionLayer.id)
  }
}

function renderAssetLayer(
  map: Map,
  layer: MapLayer,
  layerStyles: MapLayerStyles,
  assetIds: { [asset_id: number]: boolean },
  hasFilters: boolean,
  vectorTileset: VectorTileset,
  isVisible: boolean,
  beforeId: string,
  assetHazardInstances: AssetHazardInstances,
  legendsData: LegendsData,
  hazardLayers: MapLayer[],
  shouldUpdate: boolean,
) {
  const vulnerabilityPalette = generateVulnerabilityPalette(legendsData, layer.type)
  const colorExpression = getHazardColourOfAsset(
    hazardLayers,
    assetHazardInstances,
    vulnerabilityPalette,
  )

  const maxVulnerabilityRes = maxVulnerabilityExpression(hazardLayers, assetHazardInstances)
  let sortKeyExpression = maxVulnerabilityRes as Expression
  if (isNaN(parseInt(`${maxVulnerabilityRes}`))) {
    sortKeyExpression = ['number', maxVulnerabilityRes] as Expression
  }

  function getOpacity(
    visibilityExpression: Expression,
    visibleOpacity = 1,
    notVisibleOpacity = 0.3,
  ): Expression {
    return ['case', visibilityExpression, visibleOpacity, notVisibleOpacity]
  }

  let visibilityExpression: Expression | boolean = false
  if (hasFilters) {
    visibilityExpression = ['boolean', isVisible && byAssetIdIn(assetIds)]
  }

  const fillOpacityExpression =
    hasFilters && visibilityExpression
      ? getOpacity(visibilityExpression, 0.8)
      : (['literal', 0.8] as Expression)

  const lineOpacityExpression =
    hasFilters && visibilityExpression
      ? getOpacity(visibilityExpression)
      : (['literal', 1] as Expression)

  generateLayer({
    map,
    layerStyles,
    vectorTileset,
    beforeId,
    isVisible,
    vulnerabilityPalette,
    colorExpression,
    lineOpacityExpression,
    fillOpacityExpression,
    assetIds,
    assetHazardInstances,
    hazardLayers,
    sortKeyExpression,
    hasFilters,
    shouldUpdate,
  })
}

function renderInformationLayer(
  map: Map,
  layerStyles: MapLayerStyles,
  vectorTileset: VectorTileset,
  isVisible: boolean,
  beforeId: string,
  informationLayerPalette?: InformationLayerPalette,
  shouldUpdate = false,
) {
  let colorExpression: Expression

  if (!informationLayerPalette) colorExpression = ['to-color', '#888888']
  else
    switch (informationLayerPalette.type) {
      case 'continuous':
        {
          colorExpression = [
            'interpolate-lab',
            ['linear'],
            ['to-number', ['get', informationLayerPalette.dataColumn]],
          ]

          informationLayerPalette.palette.forEach((stop) => {
            colorExpression.push(stop.value)
            colorExpression.push(stop.color)
          })
        }
        break

      case 'categorical':
        {
          colorExpression = [
            'to-color',
            [
              'at',
              ['number', ['get', informationLayerPalette.dataColumn], 0], // If it's not a number return 0
              ['literal', informationLayerPalette.palette],
            ],
          ]
        }
        break

      case 'keyValue':
        {
          colorExpression = [
            'to-color',
            [
              'get',
              ['get', informationLayerPalette.dataColumn],
              ['literal', informationLayerPalette.palette],
            ],
          ]
        }
        break
    }

  const fillOpacityExpression: Expression = ['literal', vectorTileset.type == 'point' ? 1 : 0.3]
  const lineOpacityExpression: Expression = ['literal', 1]

  generateLayer({
    map,
    layerStyles,
    vectorTileset,
    beforeId,
    isVisible,
    vulnerabilityPalette: ['#0b2a48'],
    colorExpression,
    lineOpacityExpression,
    fillOpacityExpression,
    shouldUpdate,
  })
}

function renderHazardInfoLayer({
  map,
  layer,
  layerStyles,
  vectorTileset,
  isVisible,
  beforeId,
  legendsData,
  shouldUpdate,
}: {
  map: Map
  layer: MapLayer
  layerStyles: MapLayerStyles
  vectorTileset: VectorTileset
  isVisible: boolean
  beforeId: string
  legendsData: LegendsData
  shouldUpdate: boolean
}) {
  const vulnerabilityPalette = generateVulnerabilityPalette(legendsData, layer.type)
  const colorExpression = ['to-color', 'transparent'] as Expression
  const updatedTileset = {
    ...vectorTileset,
    sourceLayer: vectorTileset.id.replace('urbanintelligence.', ''),
  }
  const sortKeyExpression = ['number', 0] as Expression

  function getOpacity(
    visibilityExpression: Expression,
    visibleOpacity = 1,
    notVisibleOpacity = 0.3,
  ): Expression {
    return ['case', visibilityExpression, visibleOpacity, notVisibleOpacity]
  }

  const visibilityExpression: Expression | boolean = false

  const fillOpacityExpression = visibilityExpression
    ? getOpacity(visibilityExpression, 0.8)
    : (['literal', 0.8] as Expression)

  const lineOpacityExpression = visibilityExpression
    ? getOpacity(visibilityExpression)
    : (['literal', 1] as Expression)

  generateLayer({
    map,
    layerStyles,
    vectorTileset: updatedTileset,
    beforeId,
    isVisible,
    vulnerabilityPalette,
    colorExpression,
    lineOpacityExpression,
    fillOpacityExpression,
    sortKeyExpression,
    shouldUpdate,
  })
}

function generateLayer({
  map,
  layerStyles,
  vectorTileset,
  beforeId,
  isVisible,
  vulnerabilityPalette,
  colorExpression,
  lineOpacityExpression,
  fillOpacityExpression,
  assetIds,
  assetHazardInstances,
  hazardLayers,
  sortKeyExpression,
  hasFilters = false,
  shouldUpdate = false,
}: {
  map: Map
  layerStyles: MapLayerStyles
  vectorTileset: VectorTileset
  beforeId: string
  isVisible: boolean
  vulnerabilityPalette: string[]
  colorExpression: Expression
  lineOpacityExpression: Expression
  fillOpacityExpression: Expression
  assetIds?: { [asset_id: number]: boolean }
  assetHazardInstances?: AssetHazardInstances
  hazardLayers?: MapLayer[]
  sortKeyExpression?: Expression
  hasFilters?: boolean
  shouldUpdate?: boolean
}) {
  const layerStyle: MapLayerStyle = vectorTileset.style ? layerStyles[vectorTileset.style] : {}

  switch (vectorTileset.type) {
    case 'line': {
      addLineLayer({
        map,
        layerStyle,
        id: vectorTileset.id,
        beforeId,
        sourceLayer: vectorTileset.sourceLayer,
        isVisible,
        colorExpression,
        lineOpacityExpression,
        assetIds,
        sortKeyExpression,
        hasFilters,
        shouldUpdate,
      })
      return
    }

    case 'vector': {
      addVectorLayer({
        map,
        layerStyle,
        id: vectorTileset.id,
        beforeId,
        sourceLayer: vectorTileset.sourceLayer,
        isVisible,
        colorExpression,
        fillOpacityExpression,
        assetIds,
        sortKeyExpression,
        hasFilters,
        shouldUpdate,
      })
      return
    }

    case 'point': {
      addPointLayer({
        map,
        layerStyle,
        id: vectorTileset.id,
        beforeId,
        sourceLayer: vectorTileset.sourceLayer,
        isVisible,
        colorExpression,
        fillOpacityExpression,
        assetIds,
        sortKeyExpression,
        hasFilters,
        shouldUpdate,
      })
      return
    }

    case 'icon': {
      addIconLayer({
        map,
        layerStyle,
        id: vectorTileset.id,
        beforeId,
        isVisible,
        sourceLayer: vectorTileset.sourceLayer,
        icon: vectorTileset.icon,
        assetHazardInstances,
        hazardLayers,
        vulnerabilityPalette,
        assetIds,
        sortKeyExpression,
        hasFilters,
        shouldUpdate,
      })
      return
    }
    default:
      return <></>
  }
}
