/** @jsxImportSource @emotion/react */
import axios from '@src/utils/customAxios'
import { ChartDataItem, MakeBarChartSvg } from '@src/components/Molecules/Charts/Barchart'
import { DrawArea, MapLayer } from '@redux/map/mapSlice'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import { MakeGaugeSvg } from '@src/components/Molecules/Charts/Gauge'
import { capitalizeFirstLetter, englishList } from '@src/utils/strings.utils'
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces'
import { LegendsData } from '@src/components/Molecules/MapView/AddLayersDialog'
import { GenerateTitlePageContent } from './Pages/TitlePage'
import { GenerateSummaryPageContent } from './Pages/SummaryPage'
import { GenerateBackPageContent } from './Pages/BackPage'
import { GenerateMapPageContent } from './Pages/MapPage'
import mapboxgl from 'mapbox-gl'
import { pageSizeDimensions } from '@src/components/Pages/Map/MapTools/ExportMap/pdfGeneratorUtils'
import { Theme } from '@mui/material'
import { HorizontalBar, getLayerIcons } from '../Report.utils'
import { GenerateTableOfContents } from './Pages/TableOfContents'
import { GenerateDataSourceContent } from './Pages/LayerDetails'
import * as turf from '@turf/turf'
import { formatArea } from '@src/components/Pages/Map/MapTools/MapTools'
import { ProjectedRiskChartState } from '@redux/projectedRiskChartSlice'
import { MakeProjectedRiskChartSvg } from '../../Charts/ProjectedRiskChart/ProjectedRiskChart'
import { MakeHistogramSvg } from '../../Charts/Histogram'
import { buildHistogramData } from '../../SideDrawerContent/tabs/InformativePanel/RiskSummaryTab/RiskContent'
import { fetchAssetExposureStats } from '@contexts/RiskFetcherContext/RiskFetcher.utils'
import { RiskData } from '@contexts/RiskFetcherContext/RiskFetcher.types'

export interface Size {
  width: number
  height: number
}

const pageMargins = { top: 40, bottom: 40, left: 40, right: 40 }
const pageDimensions = pageSizeDimensions['A4']
const a4Size: Size = { width: pageDimensions[0], height: pageDimensions[1] }
const bodySize: Size = {
  width: a4Size.width - pageMargins.left - pageMargins.right,
  height: a4Size.height - pageMargins.top - pageMargins.bottom,
}

export const generateReport = async (
  layers: MapLayer[],
  drawAreas: DrawArea[],
  theme: Theme,
  regionMasks: RegionOption[] | null,
  consequencePalette: { [key: string]: string },
  legendsData: LegendsData,
  map: mapboxgl.Map | null,
  clientName: string,
  prefer_hazard_term: boolean,
  projectedRiskChartState: ProjectedRiskChartState,
  cascadingAssetNDays: { [assetId: string]: number | null },
  isMinorMajor: boolean,
): Promise<TDocumentDefinitions> => {
  const assetLayers = layers.filter((layer) => layer.layerType == 'asset')
  const hazardLayers = layers.filter((layer) => layer.layerType == 'hazard')
  const regions = regionMasks ? regionMasks.map((mask: RegionOption) => mask.region) : []
  const freshRiskData = await fetchAssetExposureStats(assetLayers, hazardLayers, regions, drawAreas)

  const uiLogoSimpleWhiteSvg: string = (await axios.get('/logos/UILogoSimpleWhite.svg')).data

  const layerIcons: { [iconId: string]: string } = await getLayerIcons(layers)

  const getIconSvg = (icon: string): string => {
    if (!icon) return layerIcons['default']
    const iconId = icon.replace('s3://', '').replace('.svg', '').toLowerCase()
    return layerIcons[iconId]
  }

  function GenerateRiskSummaryContent(): Content[] {
    const toReturn: Content[] = []
    toReturn.push({
      text: clientName == 'christchurch' ? 'Hazard Summary' : 'Risk Summary',
      style: 'h1',
      tocItem: true,
      tocMargin: [0, 10, 0, 0],
    })

    const hasSelectionArea = drawAreas.length > 0
    let selectionArea = ''
    if (hasSelectionArea) selectionArea = formatArea(turf.area(drawAreas[0]))

    const hasRegions = regions.length > 0
    let regionsEnglishList = ''
    if (hasRegions) regionsEnglishList = englishList(regions)

    if (hasSelectionArea && hasRegions)
      toReturn.push({ text: `For the ${selectionArea} selected in ${regionsEnglishList}.` })
    else if (hasSelectionArea) toReturn.push({ text: `For the ${selectionArea} selected.` })
    else if (hasRegions) toReturn.push({ text: `For ${regionsEnglishList}.` })

    assetLayers.forEach((assetLayer) => {
      const stack: Content[] = [
        {
          table: {
            body: [
              [
                { svg: getIconSvg(assetLayer.icon), fit: [13, 13] },
                {
                  text: assetLayer.display_name,
                  style: 'h2',
                  tocItem: true,
                  tocMargin: [20, 0, 0, 0],
                  margin: [0, 0, 0, 0],
                },
              ],
            ],
          },
          layout: 'noBorders',
          margin: [0, 10, 0, 10],
        },

        HorizontalBar(bodySize.width),
      ]

      // So that a page break doesn't appear between the section title and the first piece of content
      // Get the first piece of content into an unbreakable stack and add the rest after
      const firstHazardLayer = hazardLayers[0]
      stack.push(
        makeRiskContent(
          firstHazardLayer.display_name,
          getIconSvg(firstHazardLayer.icon),
          freshRiskData,
          assetLayer,
          firstHazardLayer,
          consequencePalette,
          projectedRiskChartState,
          cascadingAssetNDays,
          isMinorMajor,
        ),
      )

      toReturn.push({ stack, unbreakable: true })

      const theRest: Content[] = []
      hazardLayers.forEach((hazardLayer, index) => {
        if (index == 0) return // Skip the first as it's added above
        theRest.push(
          makeRiskContent(
            hazardLayer.display_name,
            getIconSvg(hazardLayer.icon),
            freshRiskData,
            assetLayer,
            hazardLayer,
            consequencePalette,
            projectedRiskChartState,
            cascadingAssetNDays,
            isMinorMajor,
          ),
        )
      })
      toReturn.push({ stack: theRest })
    })

    return toReturn
  }

  return {
    content: [
      ...(await GenerateTitlePageContent(clientName)),
      ...GenerateSummaryPageContent(),
      ...GenerateTableOfContents(),
      ...(await GenerateMapPageContent(map, bodySize, legendsData, layers, null, theme, true)),
      ...GenerateRiskSummaryContent(),
      ...(await GenerateDataSourceContent(layers, prefer_hazard_term)),
      ...(await GenerateBackPageContent()),
    ],
    // Images currently disabled as any that 404 or error stops PDFMake
    // images: { ...extractLayerDetailsReportsImages(layerDetailsReports) },

    // Roboto used because Inter has severe problems with macrons
    defaultStyle: { font: 'Roboto', color: '#0b2948' },
    footer: (currentPage, totalPages, pageSize) => {
      if (currentPage <= 2 || currentPage == totalPages) return null
      const even = currentPage % 2 == 0
      return [
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: pageSize.width,
              h: 40,
              color: '#0b2948',
            },
          ],
          absolutePosition: { x: 0, y: 0 },
        },
        {
          table: {
            widths: ['*', 'auto', '*'],
            body: [
              even
                ? [
                    {
                      svg: uiLogoSimpleWhiteSvg,
                      height: 20,
                      marginTop: 8,
                    },
                    {
                      text: [
                        'Urban Intelligence® Resilience Explorer® - visit ',
                        {
                          text: 'www.resilience-explorer.com',
                          link: 'http://www.resilience-explorer.com',
                          decoration: 'underline',
                        },
                        ' for more insights',
                      ],
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                    {
                      text: currentPage,
                      alignment: 'center',
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                  ]
                : [
                    {
                      text: currentPage,
                      alignment: 'center',
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                    {
                      text: [
                        'Urban Intelligence® Resilience Explorer® - visit ',
                        {
                          text: 'www.resilience-explorer.com',
                          link: 'http://www.resilience-explorer.com',
                          decoration: 'underline',
                        },
                        ' for more insights',
                      ],
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                    {
                      svg: uiLogoSimpleWhiteSvg,
                      height: 20,
                      marginTop: 8,
                    },
                  ],
            ],
          },
          margin: [15, 0, 15, 0], // [left, top, right, bottom]
          layout: 'noBorders',
        },
      ]
    },
    styles: {
      h1: {
        fontSize: 24,
        bold: false,
        margin: [0, 10, 0, 10],
      },
      h2: {
        fontSize: 16,
        bold: true,
        margin: [0, 15, 0, 0],
      },
      h3: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
    },
    pageMargins: [pageMargins.left, pageMargins.top, pageMargins.right, pageMargins.bottom],
  }
}

function makeRiskContent(
  contentTitle: string,
  iconSvg: string,
  riskData: RiskData[],
  elementLayer: MapLayer,
  riskLayer: MapLayer,
  palette: {
    [key: string]: string
  },
  projectedRiskChartState: ProjectedRiskChartState,
  cascadingAssetNDays: { [assetId: string]: number | null },
  isMinorMajor: boolean,
): Content[] {
  const theRiskData = riskData.find(
    (riskDatum) =>
      riskDatum.asset_type == elementLayer.type && riskDatum.hazard_scenario == riskLayer.assetTag,
  )

  const heading: Content = {
    table: {
      body: [
        [
          { svg: iconSvg, margin: [0, 1, 0, 0], fit: [13, 13] },
          {
            text: contentTitle,
            style: 'h3',
            tocItem: true,
            tocMargin: [40, 0, 0, 0],
            margin: [0, 0, 0, 0],
          },
        ],
      ],
    },
    layout: 'noBorders',
    margin: [0, 0, 0, 10],
  }

  if (!theRiskData || !theRiskData.data)
    return [heading, { text: 'No stats available', margin: [0, 0, 0, 60] }]

  const data = theRiskData.data
  let chartData: ChartDataItem[] = [
    { label: 'Potential', value: data.totals.potential_vulnerability, color: palette['potential'] },
    { label: 'Exposed', value: data.totals.exposed_vulnerability, color: palette['exposed'] },
    { label: 'Low', value: data.totals.low_vulnerability, color: palette['low'] },
    { label: 'Moderate', value: data.totals.medium_vulnerability, color: palette['moderate'] },
    { label: 'High', value: data.totals.high_vulnerability, color: palette['high'] },
    { label: 'Unspecified', value: data.totals.unspecified_vulnerability, color: '#aaa' },
  ]
  const lowModHighIsSet = chartData.some(
    (item) => ['Low', 'Moderate', 'High'].includes(item.label) && item.value > 0,
  )
  chartData = chartData.filter(
    (item) => item.value || (['Low', 'Moderate', 'High'].includes(item.label) && lowModHighIsSet),
  )
  const exposureTotal = Math.round(data.totals.total * 100) / 100

  let article = ''
  if (riskLayer.scenario_name?.includes('-in-')) article = 'a '
  if (riskLayer.scenario_name == 'present day') article = 'the '

  let comma = ''
  if (riskLayer.scenario_name?.includes(' with ')) comma = ','

  const summaryText = `For ${
    riskLayer.scenario_name ? `${article}${riskLayer.scenario_name}` : 'this scenario'
  }${comma} approximately ${exposureTotal.toLocaleString()} ${
    elementLayer.metric_type === 'count' ? '' : `${elementLayer.unit} of `
  }${elementLayer.display_name} are threatened.`

  const barchartDivElement = document.createElement('div')
  const xAxisTitle = `${
    elementLayer.metric_type === 'count'
      ? 'Number'
      : capitalizeFirstLetter(elementLayer.unit?.trimStart() ?? '')
  } of ${elementLayer.display_name}`

  MakeBarChartSvg(barchartDivElement, chartData, xAxisTitle, undefined, 400)

  const gaugeDivElement = document.createElement('div')
  MakeGaugeSvg(
    gaugeDivElement,
    data.totals.total,
    data.total_metric,
    capitalizeFirstLetter(elementLayer.unit?.trimStart() ?? ''),
  )

  const useCascading = cascadingAssetNDays !== null && elementLayer.is_cascading
  const histogramData = buildHistogramData(
    useCascading ? theRiskData.data.n_days_buckets : theRiskData.data.exposure_buckets,
    palette,
    isMinorMajor,
  )
  const histogramSvgDivElement = document.createElement('div')
  const hazardUnit = riskLayer.tilesets[0]?.unit

  MakeHistogramSvg(
    histogramSvgDivElement,
    histogramData,
    +theRiskData.data.bucket_size,
    useCascading ? `Days since event` : `Exposure${hazardUnit ? ` (${hazardUnit})` : ''}`,
    useCascading ? 'Outages' : capitalizeFirstLetter(elementLayer.unit || '') + ' Exposed',
    450,
    () => {
      return
    },
  )

  const projectedRiskChartDivElement = document.createElement('div')
  const projectedRiskChartParams =
    projectedRiskChartState[elementLayer.id][riskLayer.hazard_id ?? '']
  MakeProjectedRiskChartSvg({
    ...projectedRiskChartParams,
    svgElement: projectedRiskChartDivElement,
    width: 450,
    consequencePalette: palette,
  })

  return [
    {
      stack: [
        heading,
        { text: summaryText, fontSize: 11 },
        {
          table: {
            widths: ['60%', '40%'],
            body: [
              [
                {
                  text: 'Damage State Distribution',
                  bold: false,
                  marginTop: 20,
                  alignment: 'left',
                  fontSize: 11,
                  color: '#53687e',
                },
                {
                  text: `Percentage Exposed`,
                  bold: false,
                  marginTop: 20,
                  alignment: 'center',
                  fontSize: 11,
                  color: '#53687e',
                },
              ],
              [
                { svg: barchartDivElement.innerHTML, width: 300 },
                { svg: gaugeDivElement.innerHTML },
              ],
            ],
          },
          layout: 'noBorders',
        },
        {
          text: `Exposure Distribution`,
          bold: false,
          marginTop: 20,
          alignment: 'left',
          fontSize: 11,
          color: '#53687e',
        },
        { svg: histogramSvgDivElement.innerHTML, height: 170 },
        {
          text: `Projected Risk${
            projectedRiskChartParams.secondaryParameter
              ? ` - ${projectedRiskChartParams.secondaryParameterValue}`
              : ''
          }`,
          bold: false,
          marginTop: 20,
          alignment: 'left',
          fontSize: 11,
          color: '#53687e',
        },
        { svg: projectedRiskChartDivElement.innerHTML, height: 170 },
      ],
      unbreakable: true,
    },
  ]
}
