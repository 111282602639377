/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useRef } from 'react'
import { useMap } from '@contexts/MapContext'
import { useTheme } from '@mui/material'
import { updateMapStateProperty } from '@redux/map/mapSlice'
import mapboxgl, { Map, MapboxGeoJSONFeature, MapLayerMouseEvent } from 'mapbox-gl'
import ReactDOMServer from 'react-dom/server'
import { RootState } from '@redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { selectionMeasurementContainer, selectionMeasurementHeader } from './RiskMapView.styles'
import {
  addSelectedMarkerToMap,
  getAssetAndHazardDetailsForSelectedFeature,
  getCenterOfFeature,
  getHazardInfoForSelectedFeature,
  getInformationDetailsForSelectedFeature,
  getVisibleLayersByType,
} from './RiskMapView.utilities'
import { useLayers } from '@contexts/LayerContext'
import { MapTooltip } from '../MapTooltip/MapTooltip'
import { Modal } from '@uintel/ui-component-library'
import { AddLayersDialog } from './AddLayersDialog'
import { useTutorialContext } from '@contexts/TutorialContext'
import { createPortal } from 'react-dom'
import { GenericMapView } from './GenericMapView'
import { usePreferences } from '@contexts/PreferencesContext'
import { css } from '@emotion/react'

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

export type AssetHazardInstance = {
  vulnerability_style: number
  vulnerability: string
  metric: number
  exposure_value: string
}
export type AssetHazardInstances = {
  [hazardAssetTag: string]: { [assetId: string]: AssetHazardInstance }
}
export type VulnerabilityData = {
  [assetType: string]: AssetHazardInstances
}

export const RiskMapView = () => {
  mapboxgl.accessToken = MAPBOX_TOKEN
  let tooltipTimeout: NodeJS.Timeout
  const theme = useTheme()

  const dispatch = useDispatch()
  const {
    map,
    selectFeature,
    deselectFeature,
    regionMasks,
    showAddLayersModalTab,
    setShowAddLayersModalTab,
  } = useMap()
  const { term_preference } = usePreferences()
  const { layers, drawAreas, legendsData, selectedMarker, selectedFeature, canInteractWithLayers } =
    useSelector((state: RootState) => state.map)
  const selectedMapboxGeoJsonFeature = selectedFeature as MapboxGeoJSONFeature
  const { visibleAssets, prepareAndUpdateLayers } = useLayers()
  const { tab } = useSelector((state: RootState) => state.sideDrawer)

  const geocoderEnabled = useMemo(() => tab !== 'Stories', [tab])

  const previousMouseEvents = useRef<{ [key: string]: (e: MapLayerMouseEvent) => void }>({})
  const mouseEventsLayerIDTracker = useRef<string[]>([])

  const tooltipRef = useRef(new mapboxgl.Popup({ closeButton: false }))
  const assetLayers = getVisibleLayersByType(layers, 'asset')
  const hazardLayers = getVisibleLayersByType(layers, 'hazard')
  const informationLayers = getVisibleLayersByType(layers, 'information')

  const tooltipFeature = useRef<MapboxGeoJSONFeature | undefined>()

  async function controlMouseEvents(map: Map) {
    if (
      selectedMarker &&
      selectedMapboxGeoJsonFeature &&
      layers.map((l) => l.id).includes(selectedMapboxGeoJsonFeature.layer.id)
    ) {
      addSelectedMarkerToMap({
        map,
        coordinates: [selectedMarker.longitude, selectedMarker.latitude],
        colour: theme.palette.primary.main,
      })
    }
    const lookupTileSetIds = hazardLayers.reduce((acc, layer) => {
      if (layer.infoLookupTileset?.length) {
        for (const lookupTileSet of layer.infoLookupTileset) {
          if (lookupTileSet.id) acc.push(lookupTileSet.id)
        }
      }
      return acc
    }, [] as string[])

    if (Object.keys(visibleAssets).length >= 1) {
      addMouseEvents(map, [
        ...Object.keys(visibleAssets),
        ...informationLayers.map((layer) => layer.id),
        ...lookupTileSetIds,
      ])
    } else if (
      assetLayers.length >= 1 ||
      lookupTileSetIds.length >= 1 ||
      (informationLayers.length >= 1 && drawAreas.length === 0 && !regionMasks)
    ) {
      addMouseEvents(map, [
        ...assetLayers.map((layer) => layer.type),
        ...informationLayers.map((layer) => layer.id),
        ...lookupTileSetIds,
      ])
    }
  }

  function removeMouseEvents(map: Map) {
    const existingLayers = map
      .getStyle()
      .layers.filter((layer) => layer.id.includes('urbanintelligence'))
      .filter((layer) => !layer.id.includes('-line'))

    // both existingLayers and mouseEventsLayerIDTracker are necessary to remove mouse events
    existingLayers.forEach((layer) => {
      if (!layer) return
      map.off('mouseenter', layer.id, previousMouseEvents.current['mouseenter'])
      map.off('mousemove', layer.id, previousMouseEvents.current['mousemove'])
      map.off('mouseleave', layer.id, previousMouseEvents.current['mouseleave'])
      map.off('click', layer.id, previousMouseEvents.current['click'])
    })
    mouseEventsLayerIDTracker.current.forEach((layerId) => {
      map.off('mouseenter', layerId, previousMouseEvents.current['mouseenter'])
      map.off('mousemove', layerId, previousMouseEvents.current['mousemove'])
      map.off('mouseleave', layerId, previousMouseEvents.current['mouseleave'])
      map.off('click', layerId, previousMouseEvents.current['click'])
    })
    mouseEventsLayerIDTracker.current = []
  }

  async function addMouseEvents(map: Map, visibleAssetIds: string[]) {
    if (!canInteractWithLayers) return
    const popup = tooltipRef.current
    if (!popup) return

    const existingLayers = map
      .getStyle()
      .layers.filter((layer) => layer.id.includes('urbanintelligence'))
      .filter((layer) => !layer.id.includes('-line'))

    const isLayerInteractivityDisabled = (layer: mapboxgl.Layer) => {
      for (const assetLayer of assetLayers) {
        const tileset = assetLayer.tilesets.find((iLayer) => iLayer.id === layer.source)
        if (tileset) {
          return assetLayer.interactivityDisabled
        }
      }
      for (const infoLayer of informationLayers) {
        const tileset = infoLayer.tilesets.find((iLayer) => iLayer.id === layer.source)
        if (tileset) {
          return infoLayer.interactivityDisabled
        }
      }
      for (const hazardLayer of hazardLayers.filter((layer) => layer.infoLookupTileset?.length)) {
        const tileset = hazardLayer.tilesets.find((hLayer) => hLayer.id === layer.id)
        if (tileset) {
          return hazardLayer.interactivityDisabled
        }
      }

      return false
    }

    const layerIdMap = layers.map((layer) => {
      if (layer.infoLookupTileset?.length) return layer?.infoLookupTileset?.[0]?.id
      if (layer.tilesets) return layer.tilesets[0].id
      if (layer.id) return layer.id
      return ''
    })

    const interactiveLayers = visibleAssetIds
      .map((assetId) => {
        return existingLayers.filter((layer) => {
          // Some layers have a separate layer when zoomed out such as an icon layer where it uses points if zoomed out
          return layer.id.includes(assetId) || layer.id.includes(`${assetId}-zoomed-out`)
        })
      })
      .flat()
      .sort((a, b) => {
        return layerIdMap.findIndex((id) => id === a.id) - layerIdMap.findIndex((id) => id === b.id)
      })

    // Func to check if this feature's layer is higher priority than the one already shown
    // If true, that timeout will be replaced with a new one loading this layer
    // If no other layer is loading, this one will be loaded
    const isFeatureHigherPriorityThanAlreadyLoadingFeature = (feature: MapboxGeoJSONFeature) => {
      if (tooltipFeature.current == undefined) return true

      const layerId = feature.layer.id.replace('-zoomed-out', '') // Some layers are split into a zoomed in or zoomed out layer such as an icon layer.
      // use layers to sort instead of visibleAssetIds as visibleAssetIds is not in the correct order

      return (
        layerIdMap.findIndex((id) => id === layerId) <=
        layerIdMap.findIndex((id) => id === tooltipFeature.current?.layer.id)
      )
    }

    const updateTooltip = (e: MapLayerMouseEvent) => {
      if (!e.features || !e.features.length) return
      // Only show tooltip if the feature has a value or asset_id
      // Which is the sign of an info feature that has a description to show or if it has an asset_id it likely has a tooltip sentence
      const hasSomeDescription = e.features.some((feature) => {
        return (
          feature.properties?.value ||
          feature.properties?.display_value ||
          feature.properties?.hover_comment ||
          feature.properties?.asset_id !== null ||
          feature.properties?.asset_id !== undefined
        )
      })

      if (!hasSomeDescription) return

      const interactivityIsDisabled = e.features.some((feature) => {
        const layer = interactiveLayers.find(
          (layer: mapboxgl.Layer) => layer?.source === feature.layer.source,
        )
        const hazardLayerLookup = hazardLayers.find((hLayer) => {
          return (
            hLayer.infoLookupTileset?.length &&
            hLayer.infoLookupTileset.some((tileset) => tileset.id === feature.layer.id)
          )
        })
        let flag: boolean | undefined = false
        if (hazardLayerLookup) {
          flag = isLayerInteractivityDisabled(hazardLayerLookup)
        } else if (layer) flag = isLayerInteractivityDisabled(layer)
        return flag
      })
      if (interactivityIsDisabled) return

      map.getCanvas().style.cursor = 'pointer'

      popup.setLngLat(e.lngLat)
      popup.setMaxWidth('800px')

      // Return if the feature is already being hovered over.
      if (tooltipFeature.current && tooltipFeature.current.id === e.features[0].id) return

      // Return if the feature is lower priority than the one already loading
      if (!isFeatureHigherPriorityThanAlreadyLoadingFeature(e.features[0])) return

      // Set the feature as currently shown
      tooltipFeature.current = e.features[0]

      // Fade out outdated tooltip content (this typecast is safe btw)
      const content = document.getElementsByClassName('tooltip-container')[0] as HTMLElement
      if (content) content.style.color = '#AAA'
      clearTimeout(tooltipTimeout)
      tooltipTimeout = setTimeout(async () => {
        if (!tooltipFeature.current) return popup.remove()
        const details = await getAssetAndHazardDetailsForSelectedFeature(
          tooltipFeature.current,
          assetLayers,
          hazardLayers,
          legendsData,
        )
        const information = await getInformationDetailsForSelectedFeature(
          tooltipFeature.current,
          informationLayers,
        )
        const hazardInformation = getHazardInfoForSelectedFeature(
          tooltipFeature.current,
          hazardLayers,
        )

        if (!details && !information && !hazardInformation) return popup.remove()

        const asset = details?.asset
        const hazards = details?.hazards

        const mapToolTip = MapTooltip({
          asset,
          hazards,
          information: information || hazardInformation,
          mainColour: theme.palette.primary.main,
          prefer_hazard_term: term_preference.hazard,
          prefer_risk_term: term_preference.risk,
        })

        if (!mapToolTip) return
        popup.setHTML(ReactDOMServer.renderToString(mapToolTip))
        popup.addTo(map)
      }, 400)
    }

    // MOUSE EVENTS
    const mouseEnterEvent = function (e: MapLayerMouseEvent) {
      if (!e) return
      updateTooltip(e)
    }

    const mouseMoveEvent = (e: MapLayerMouseEvent) => {
      updateTooltip(e)
    }

    const mouseLeaveEvent = (_e: MapLayerMouseEvent) => {
      clearTimeout(tooltipTimeout)
      tooltipFeature.current = undefined
      map.getCanvas().style.cursor = ''
      popup.remove()
    }

    let selectFeatureTimeout: null | NodeJS.Timeout = null
    const mouseClickEvent = async (e: MapLayerMouseEvent) => {
      let coordinates = [e.lngLat.lng, e.lngLat.lat]

      if (typeof e.features === 'undefined') return
      const feature = e.features[0]

      if (!feature) return

      const featureCenter = getCenterOfFeature(feature)
      if (featureCenter) {
        coordinates = featureCenter
      }

      const selectedAsset = assetLayers.find(
        (assetLayer) => assetLayer.tilesets[0].id === feature.layer.source,
      )
      const selectedContextLayer = informationLayers.find(
        (infoLayer) => infoLayer.tilesets[0].id === feature.layer.source,
      )

      if (!selectFeatureTimeout) {
        selectFeatureTimeout = setTimeout(() => {
          selectFeatureTimeout = null
          let successfulSelect = false
          if (selectedContextLayer) {
            successfulSelect = selectFeature(feature, selectedContextLayer, 'selection')
          }
          if (selectedAsset) {
            successfulSelect = selectFeature(feature, selectedAsset, 'selection')
          }
          if (successfulSelect) {
            addSelectedMarkerToMap({
              map,
              coordinates: coordinates as mapboxgl.LngLatLike,
              colour: theme.palette.primary.main,
            })

            dispatch(
              updateMapStateProperty({
                selectedMarker: {
                  longitude: coordinates[0],
                  latitude: coordinates[1],
                },
              }),
            )
          }
          clearTimeout(tooltipTimeout)
          tooltipFeature.current = undefined
          tooltipRef.current.remove()
        }, 100)
      }
    }

    const mouseEvents: { [key: string]: (e: MapLayerMouseEvent) => void } = {
      mouseenter: mouseEnterEvent,
      mousemove: mouseMoveEvent,
      mouseleave: mouseLeaveEvent,
      click: mouseClickEvent,
    }

    Object.keys(mouseEvents).forEach((eventName) => {
      if (previousMouseEvents.current[eventName] == undefined) {
        previousMouseEvents.current[eventName] = mouseEvents[eventName]
      }
    })

    // Clear existing events and add new ones
    mouseEventsLayerIDTracker.current.forEach((layerId) => {
      map.off('mouseenter', layerId, previousMouseEvents.current['mouseenter'])
      map.off('mousemove', layerId, previousMouseEvents.current['mousemove'])
      map.off('mouseleave', layerId, previousMouseEvents.current['mouseleave'])
      map.off('click', layerId, previousMouseEvents.current['click'])
    })
    mouseEventsLayerIDTracker.current = []

    Object.keys(mouseEvents).forEach((eventName) => {
      previousMouseEvents.current[eventName] = mouseEvents[eventName]
    })

    interactiveLayers.forEach((layer) => {
      if (!layer) return
      map.on('click', layer.id, previousMouseEvents.current['click'])
      map.on('mouseenter', layer.id, previousMouseEvents.current['mouseenter'])
      map.on('mousemove', layer.id, previousMouseEvents.current['mousemove'])
      map.on('mouseleave', layer.id, previousMouseEvents.current['mouseleave'])
      mouseEventsLayerIDTracker.current.push(layer.id)
    })
  }

  useEffect(() => {
    async function addMapLayersAndEvents() {
      await prepareAndUpdateLayers({ layers })
      if (map) await controlMouseEvents(map)
    }
    addMapLayersAndEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    if (!map || !selectedMapboxGeoJsonFeature || !layers) return
    if (!layers.map((l) => l.id).includes(selectedMapboxGeoJsonFeature.layer.id)) {
      deselectFeature()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers])

  useEffect(() => {
    if (!map) return
    tooltipRef.current.remove()
    controlMouseEvents(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, visibleAssets, selectedMarker])

  useEffect(() => {
    if (!map) return
    if (canInteractWithLayers) {
      const lookupTileSetIds = hazardLayers.reduce((acc, layer) => {
        if (layer.infoLookupTileset?.length) {
          for (const lookupTileSet of layer.infoLookupTileset) {
            if (lookupTileSet.id) acc.push(lookupTileSet.id)
          }
        }
        return acc
      }, [] as string[])
      if (Object.keys(visibleAssets).length >= 1) {
        addMouseEvents(map, [
          ...Object.keys(visibleAssets),
          ...informationLayers.map((layer) => layer.id),
          ...lookupTileSetIds,
        ])
      } else if (
        assetLayers.length >= 1 ||
        lookupTileSetIds.length >= 1 ||
        (informationLayers.length >= 1 && drawAreas.length === 0 && !regionMasks)
      ) {
        addMouseEvents(map, [
          ...assetLayers.map((layer) => layer.type),
          ...informationLayers.map((layer) => layer.id),
          ...lookupTileSetIds,
        ])
      }
    } else removeMouseEvents(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canInteractWithLayers])

  // Add setShowAddLayersModalTab to TutorialContext
  const { openAddLayersModal } = useTutorialContext()
  useEffect(() => {
    if (openAddLayersModal) {
      setShowAddLayersModalTab('elements')
    } else {
      setShowAddLayersModalTab('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAddLayersModal])

  return (
    <>
      <GenericMapView
        customStyle={css`
          & .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
            margin-top: ${geocoderEnabled ? '80px' : '44px'};
          }
        `}
        inContainerSlot={
          drawAreas.length ? (
            <div id="selection-measurement" css={selectionMeasurementContainer}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h6 css={selectionMeasurementHeader}>Selection Details:</h6>
                <span>
                  Area: <span id="selection-calculated-area"></span>
                </span>
                <span>
                  Length: <span id="selection-calculated-length"></span>
                </span>
              </div>
            </div>
          ) : null
        }
        outsideContainerSlot={
          showAddLayersModalTab
            ? createPortal(
                <Modal open={!!showAddLayersModalTab} onClose={() => setShowAddLayersModalTab('')}>
                  <AddLayersDialog onClose={() => setShowAddLayersModalTab('')} />
                </Modal>,
                document.body,
              )
            : null
        }
      />
    </>
  )
}
